import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01';
import ModalSimple from '@solid-ui-blocks/Modal/Block02';
import Header from '@solid-ui-blocks/Header/Block01';
import Hero from '@solid-ui-blocks/Hero/Block02';
import Features from '@solid-ui-blocks/Features/Block06';
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05';
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01';
import WhyChooseUs from '@solid-ui-blocks/Features/Block04';
import GetStarted from '@solid-ui-blocks/Stats/Block01';
import Blog from '@solid-ui-blocks/Blog/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from './_theme';
import styles from './_styles';

const IndexPage = props => {
  const { allBlockContent } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  const [blogData, setBlogData] = useState();

  const getBlogData = () => {
    const blogName = content['latest-blogs'].text[0].text;
    fetch(`https://blog.eval.team/.netlify/functions/blog?name=${blogName}`)
      .then(response => response.json())
      .then((jsonData) => {
        // Modify the text here
        if (jsonData.message.text) {
          jsonData.message.text[1].text = "ASP.NET Insights: Powering Today's Web Innovations";
        }
        setBlogData(jsonData.message);
      })
      .catch((error) => {
        console.error(error);
      })
  };

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['getintouch']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Divider space='5' />
      <Features content={content['features']} />
      <Divider space='3' />
      <Divider space='3' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabOne content={content['feature-tab-one']} reverse />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-two']} />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-three']} reverse />
      </Container>
      <Divider space='3' />
      <Divider space='3' />
      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='3' />
      <Divider space='3' />
      <GetStarted content={content['get-started']} />
      <Divider space='3' />
      <Divider space='3' />
      <Blog content={blogData} />
      <Divider space='3' />
      <Divider space='3' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query technologyAspDonNet {
    allBlockContent(filter: { page: { in: ["technology/asp-dot-net", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default IndexPage;
